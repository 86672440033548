<template>
  <div style="max-width:1050px;margin:auto;padding-top:20px;">
    <template>
      <b-table :data="workspaces" ref="table">
        <b-table-column v-slot="props" label="Platz Nr.">
          {{props.row.title}}
        </b-table-column>

        <b-table-column v-slot="props" label="Beschreibung">
          {{props.row.description}}
        </b-table-column>

        <b-table-column v-slot="props" centered label="Zu Check-in">
          <a @click="toCheckInOut(props.row.id)"><b-icon type="is-success" icon="arrow-right-bold-circle"></b-icon></a>
        </b-table-column>

        <b-table-column v-slot="props" centered label="Bearbeiten">
            <a @click="activeRow = props.row, isCardModalActive = true"><b-icon type="is-grey" icon="pencil"></b-icon></a>
            <a @click="downloadLog(props.row.id)"><b-icon type="is-grey" class="ml-5" icon="download"></b-icon></a>
          <a @click="deleteCard(props.row)"><b-icon type="is-grey" icon="delete" class="ml-5"></b-icon></a>
        </b-table-column>
      </b-table>
    </template>

    <div class="has-text-centered mt-3">
      <b-button
        icon-left="plus"
        type="is-success"
        @click="activeRow = undefined, isCardModalActive = true" />
    </div>
    <b-modal
      v-model="isCardModalActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-label="Example Modal"
      aria-modal>
      <Modal :row="activeRow"/>
    </b-modal>

  </div>
</template>

<script>
import Modal from '../components/Modal.vue'
// eslint-disable-next-line no-unused-vars
import { collection, query, onSnapshot, deleteDoc, doc, getDocs, orderBy, Timestamp } from 'firebase/firestore'
import { onAuthStateChanged } from "firebase/auth"
import download from 'js-file-download'
import { db, auth } from '../firebase'

export default {
  data () {
    return {
      workspaces: [],
      activeRow: {},
      isCardModalActive: false
    }
  },
  components: {
      Modal
  },
  methods: {
    toCheckInOut(id) {
      this.$router.push({ path: `/wp/${id}` })
    },
    async downloadLog(workplaceId) {
      const q = query(collection(db, 'workplace', workplaceId, 'checkInsOuts'), orderBy('timestamp', 'asc'))
      const querySnap = await getDocs(q)
      /** @type {Array<({timestamp:Timestamp,status:('check-in'|'check-out'),userId?:string,name?:string})>} */
      const actions = querySnap.docs.map(d => ({ id: d.id, ...d.data() }))
      console.log({ actions })
      const visits = []
      for (let i = 0; i < actions.length; i++) {
        const action = actions[i];
        if(action.status === 'check-in'){
          const checkOut = actions.slice(i + 1).find(a => a.status === 'check-out')
          if(typeof checkOut !== 'undefined'){
            visits.push({
              name: action.name || '',
              start: action.timestamp.toDate().toISOString() || '',
              end: checkOut.timestamp.toDate().toISOString() || ''
            })
          }
        }
      }
      const csv = [{ name: 'NAME', start: 'CHECK_IN', end: 'CHECK_OUT' }, ...visits].map(
        ({ name, start, end }) => `${name.replace(/;/g, '')};${start.replace(/;/g, '')};${end.replace(/;/g, '')}`
      ).join('\n')
      download(csv, 'export.csv')
    },
    deleteCard (row) {
      this.$buefy.dialog.confirm({
        title: 'Workplace löschen',
        message: 'Sicher dass du den Workplace <b>löschen</b> möchtest? Diese Aktion kann nicht mehr rückgängig gemacht werden.',
        confirmText: 'Löschen',
        type: 'is-warning',
        hasIcon: true,
        onConfirm: async () => await deleteDoc(doc(db, 'workplace', row.id))
      })
    },
  },
    async created() {
      console.log(auth.currentUser)
      if (auth.currentUser === null) {
        this.$router.push({ name: 'Login' })
      } else {
        onAuthStateChanged(auth,
          () => {
            const q = query(collection(db, "workplace"))
            onSnapshot(q, (snapshot) => {
              this.workspaces = snapshot.docs.map((doc) => {
                return { ...doc.data(), id: doc.id }
              })
            })
          }
        )
      }
  },
}
</script>

<style>
</style>
