<template>
  <form action="">
    <div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">
            {{ row !== undefined ? row.title : "" }}
          </p>
          <button type="button" class="delete" @click="$parent.close()" />
        </header>
        <div class="modal-card-content">
          <div class="modal-card-body pt-2">
            <b-field label="Titel">
              <b-input v-model="title" required></b-input>
            </b-field>
            <b-field label="Beschreibung">
              <b-input type="textarea" v-model="description"></b-input>
            </b-field>
            <div class="columns">
              <b-field class="column" label="Aktiv oder inaktiv">
                <b-switch v-model="isActive">Aktiv</b-switch>
              </b-field>
              <b-field class="column pt-5 mt-3">
                <b-button v-if="row" @click="qrCode" class="is-greyy" :loading="qrLoading">Download QR-Code</b-button>
                <b-button v-else disabled>Download QR-Code</b-button>
              </b-field>
            </div>
          </div>
        </div>
        <div class="modal-card-foot">
          <b-button @click="$parent.close()">Zurück</b-button>
          <b-button @click="save" class="is-success" :loading="loading">Speichern</b-button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { addDoc, collection, updateDoc, doc } from "firebase/firestore"
import { db, fn } from "../firebase"
import { httpsCallable } from "firebase/functions"

export default {
  props: ["row"],
  data() {
    return {
      isActive: true,
      title: "",
      description: "",
      isBlocked: false,
      link: "",
      loading: false,
      qrLoading: false
    };
  },
  methods: {
    async qrCode() {
      this.qrLoading = true
      const result = await httpsCallable(fn, "createQrPdf")({ id: this.row.id })
      let pdfWindow = window.open("")
      pdfWindow.document.write(
        `<iframe width='100%' height='100%' style="border:0" src='${result.data}'></iframe><style>body{margin: 0;}</style>`
      );
      this.qrLoading = false
      console.log(result.data)
    },
    async save() {
      if (this.title) {
        this.loading = true
        if (!this.isActive) {
          this.isBlocked = true
        }
        if (typeof this.row === 'undefined') {
          await addDoc(collection(db, "workplace"), {
            title: this.title,
            description: this.description,
            active: this.isActive,
            blocked: this.isBlocked
          })
        } else {
          await updateDoc(doc(db, "workplace", this.row.id), {
            title: this.title,
            description: this.description,
            active: this.isActive,
            blocked: this.isBlocked
          })
        }
        this.loading = false
        this.$parent.close()
      } else {
        this.$buefy.toast.open('Bitte Titel eingeben')
      }
    },
  },
  mounted () {
      this.title = this.row.title || ''
      this.description = this.row.description || ''
      this.isActive = this.row.active || true
  }
};
</script>

<style>
</style>
